var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Authentication',{attrs:{"Title":"Welcome back","Description":"Sign in to continue"},on:{"togglePopup":function($event){_vm.$emit('togglePopup', $event);
    _vm.resetInputs();}}},[_c('div',{staticClass:"input-group",class:{ invalid: _vm.emailError.length > 0 }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:{ hasValue: _vm.email.length > 0, invalid: _vm.emailError.length > 0 },attrs:{"type":"text","id":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('label',{attrs:{"for":"email"}},[_vm._v("Email Address")]),_c('div',{staticClass:"artificial-outline"}),(_vm.emailError.length > 0)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.emailError))]):_vm._e()]),_c('div',{staticClass:"input-group",class:{ invalid: _vm.passwordError.length > 0 },attrs:{"id":"password-group"}},[((_vm.inputType(_vm.showPassword))==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
        hasValue: _vm.password.length > 0,
        invalid: _vm.passwordError.length > 0
      },attrs:{"id":"password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.inputType(_vm.showPassword))==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
        hasValue: _vm.password.length > 0,
        invalid: _vm.passwordError.length > 0
      },attrs:{"id":"password","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
        hasValue: _vm.password.length > 0,
        invalid: _vm.passwordError.length > 0
      },attrs:{"id":"password","type":_vm.inputType(_vm.showPassword)},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('div',{staticClass:"artificial-outline"}),(!_vm.showPassword)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/eye.svg")},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_vm._e(),(_vm.showPassword)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/eye-slash.svg")},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_vm._e(),(_vm.passwordError.length > 0)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.passwordError))]):_vm._e()]),_c('div',{staticClass:"redirect"},[_c('button',{on:{"click":function($event){_vm.$emit('togglePopup', 'forgot-password');
        _vm.resetInputs();}}},[_vm._v(" Forgot password? ")]),_c('p',[_vm._v(" New here? "),_c('button',{on:{"click":function($event){_vm.$emit('togglePopup', 'register');
          _vm.resetInputs();}}},[_vm._v(" Sign Up ")])])]),_c('button',{attrs:{"disabled":_vm.processing},on:{"click":function($event){return _vm.signin()}}},[_vm._v(" "+_vm._s(_vm.processing ? "Logging in..." : "Login")+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }